import React, { useState, useEffect } from 'react';
import './Dashboard.css';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('home');
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [botRunning, setBotRunning] = useState(false); // Track bot's status

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await fetch('/api/user');
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setSettings(data.user.settings);
          setLoading(false);
          await checkBotStatus(data.user.id);
        } else {
          console.error('Failed to load user data:', response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    }

    fetchUserData();
  }, []);

  const checkBotStatus = async (userId) => {
    try {
      const response = await fetch(`/api/bot/status/${userId}`);
      if (response.ok) {
        const data = await response.json();
        setBotRunning(data.isRunning); // Set the bot running state based on the response
      } else {
        console.error('Failed to check bot status:', response.statusText);
      }
    } catch (error) {
      console.error('Error checking bot status:', error);
    }
  };

  const handleTabChange = (tab) => setActiveTab(tab);

  const toggleBotEnabled = async () => {
    const updatedBotEnabled = !settings.botEnabled;
    try {
      const response = await fetch('/api/settings/bot-enabled', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ botEnabled: updatedBotEnabled }),
      });

      const data = await response.json();
      if (response.ok) {
        setSettings((prev) => ({
          ...prev,
          botEnabled: updatedBotEnabled,
        }));
      } else {
        alert('Failed to update bot status.');
      }
    } catch (error) {
      alert('An unexpected error occurred while updating bot status.');
    }
  };

  const saveSettings = async () => {
    if (!settings) return;
    try {
      const response = await fetch('/api/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(settings),
      });

      const data = await response.json();
      if (response.ok) {
        setSettings(data.settings);
        alert('Settings updated successfully!');
      } else {
        alert(`Error saving settings: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while saving settings.');
    }
  };

  const startBot = async () => {
    try {
      const response = await fetch('/api/bot/start', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Unknown error occurred');
      }

      const data = await response.json();
      setBotRunning(true); // Set botRunning to true when the bot starts
    } catch (error) {
      alert('Failed to start bot.');
    }
  };

  const stopBot = async () => {
    try {
      if (!user || !user.id) {
        alert('User ID is not available.');
        return;
      }

      const response = await fetch('/api/bot/stop', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.id }),
      });

      const data = await response.json();
      if (response.ok) {
        setBotRunning(false); // Set botRunning to false when the bot stops
        alert('Bot stopped successfully!');
      } else {
        alert(`Error stopping bot: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while stopping the bot.');
    }
  };

  if (loading) return <p>Loading...</p>;

  if (!settings) return <p>Error loading settings.</p>;

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <h2>Dashboard</h2>
        <ul>
          <li
            className={activeTab === 'home' ? 'active' : ''}
            onClick={() => handleTabChange('home')}
          >
            Home
          </li>
          <li
            className={activeTab === 'settings' ? 'active' : ''}
            onClick={() => handleTabChange('settings')}
          >
            Bot Settings
          </li>
        </ul>
      </div>
      <div className="content">
        {activeTab === 'home' && (
          <div className="home-tab">
            <h3>Welcome, {user ? user.displayName : 'User'}</h3>
            <p>Status: {settings.botEnabled ? 'Enabled' : 'Disabled'}</p>
            <button
              onClick={toggleBotEnabled}
              disabled={botRunning}
            >
              {settings.botEnabled ? 'Disable Bot' : 'Enable Bot'}
            </button>
            <button onClick={startBot} disabled={botRunning}>Start Bot</button>
            <button onClick={stopBot} disabled={!botRunning}>Stop Bot</button>
          </div>
        )}
        {activeTab === 'settings' && (
          <div className="settings-tab">
            <h3>Bot Personality</h3>
            <textarea
              value={settings.personalityPrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  personalityPrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter bot's personality prompt"
            />
            <h3>Event Responses</h3>
            <h4>Follow Event</h4>
            <textarea
              value={settings.followPrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  followPrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter follow event bot response"
            />
            <h4>Subscription Event</h4>
            <textarea
              value={settings.subscribePrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  subscribePrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter subscription event bot response"
            />
            <h4>Cheer Event</h4>
            <textarea
              value={settings.cheerPrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  cheerPrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter cheer event bot response"
            />
            <h4>Raid Event</h4>
            <textarea
              value={settings.raidPrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  raidPrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter raid event bot response"
            />
            <button onClick={saveSettings}>Save Settings</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
